<template>
  <b-card>
    <b-modal
      id="credit-voucher-modal"
      no-close-on-backdrop
      title="Add Credit Voucher"
      ok-title="Save"
      cancel-variant="outline-secondary"
      @ok="saveCreditVoucher"
      @cancel="resetCreditVoucher"
      @close="resetCreditVoucher"
      @ok.prevent
    >
      <b-form @submit.prevent>
        <b-row>
          <!-- <b-col cols="8">
          <b-form-group
            label="Requisition No:"
            label-for="requisition_no"
            label-cols-md="3"
          >
            <b-form-input
              id="requisition_no"
              type="number"
              placeholder="Requisition No"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="Requisition Amount:"
            label-for="requisition_amount"
            label-cols-md="3"
          >
            <b-form-input
              id="requisition_amount"
              type="number"
              placeholder="Requisition Amount"
            />
          </b-form-group>
        </b-col> -->
          <b-col cols="12">
            <b-form-group
              label="Customer"
              label-for="customer"
              label-cols-md="3"
              :state="customerState"
              invalid-feedback="Customer is Required"
            >
              <b-form-input
                id="customer"
                v-model="creditVoucherFormData.customer"
                placeholder="Type Customer Name"
                :state="customerState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Debit"
              label-for="debit"
              label-cols-md="3"
              :state="debitState"
              invalid-feedback="Account Head is Required"
            >
              <v-select
                v-model="creditVoucherFormData.debit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                placeholder="Select Account Head"
                :options="accountOptions"
                :reduce="val => val.id"
                :state="debitState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Credit"
              label-for="credit"
              label-cols-md="3"
              :state="creditState"
              invalid-feedback="Payment Mode is Required"
            >
              <v-select
                v-model="creditVoucherFormData.credit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                placeholder="Select Payment Mode"
                :options="paymentModeOptions"
                :reduce="val => val.id"
                :state="creditState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Amount"
              label-for="amount"
              label-cols-md="3"
              :state="amountState"
              invalid-feedback="Amount is Required"
            >
              <b-form-input
                id="amount"
                v-model="creditVoucherFormData.amount"
                type="number"
                placeholder="Amount"
                :state="amountState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Note"
              label-for="note"
              label-cols-md="3"
              :state="noteState"
              invalid-feedback="Note is Required"
            >
              <b-form-textarea
                id="note"
                v-model="creditVoucherFormData.note"
                placeholder="Reason (Required)"
                rows="3"
                max-rows="6"
                :state="noteState"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <!-- <b-col cols="12">
            <b-form-group
              label-for="buttons"
              label-cols-md="3"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="saveCreditVoucher"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-form-group>
          </b-col> -->
        </b-row>
      </b-form>
    </b-modal>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Credit Voucher
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <voucher-table
      voucher-type="credit"
      :debit-coa=" getCreditVoucherDebitCoa"
      :credit-coa="getCreditVoucherCreditCoa"
    />
    <!--    activiy modal-->
    <b-modal
      id="credit-activity-modal"
      no-close-on-backdrop
      title="Credit Voucher activity log"
      size="lg"
      hide-footer
    >
      <div :style="isLoadingAudit ? { paddingBottom:'60px'} : { padding:''}">
        <preloader
          v-if="isLoadingAudit"
        />
        <activity-timeline
          v-if="!isLoadingAudit"
          :rows="audits"
          :total-records="auditTotal"
          :server-props-params="auditServerParams"
          :on-column-filter-server="onColumnFilterAuditServer"
          :refresh-grid="onRefreshAudit"
        />
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VoucherTable from './VoucherTable.vue'
import AuditService from '@/@service/api/Audit.service'
import Preloader from '../components/preloader/preloader.vue'
import ActivityTimeline from '../audit/components/ActivityTimeline.vue'

import VoucherService from '@/@service/api/accounting/Voucher.service'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormTextarea,
    vSelect,
    VoucherTable,
    BLink,
    Preloader,
    ActivityTimeline,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      creditVoucherFormData: {},
      accountSelected: null,
      accountOptions: [],
      paymentModeSelected: null,
      paymentModeOptions: [],

      customerState: null,
      debitState: null,
      creditState: null,
      amountState: null,
      noteState: null,

      isLoadingAudit: false,
      tag: 'credit-voucher',
      audits: [],
      auditTotal: 0,
      auditServerParams: {
        columnFilters: {},
        dateRange: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },

    }
  },
  methods: {
    validate() {
      this.customerState = null
      this.debitState = null
      this.creditState = null
      this.amountState = null
      this.noteState = null
      if (!this.creditVoucherFormData.customer) {
        this.customerState = false
      }
      if (!this.creditVoucherFormData.debit || (this.creditVoucherFormData.debit && this.creditVoucherFormData.debit.length < 1)) {
        this.debitState = false
      }
      if (!this.creditVoucherFormData.credit || (this.creditVoucherFormData.credit && this.creditVoucherFormData.credit.length < 1)) {
        this.creditState = false
      }
      if (!this.creditVoucherFormData.amount) {
        this.amountState = false
      }
      if (!this.creditVoucherFormData.note) {
        this.noteState = false
      }
      if (this.customerState !== false && this.debitState !== false && this.creditState !== false
        && this.amountState !== false && this.noteState !== false) {
        return true
      }
      return false
    },

    saveCreditVoucher() {
      if (!this.validate()) {
        return
      }
      VoucherService.saveCreditVoucher(this.creditVoucherFormData).then(res => {
        if (res.data.status) {
          this.creditVoucherFormData = {}
          this.$root.$emit('bv::hide::modal', 'credit-voucher-modal')
          this.$root.$emit('voucherSubmitted', 'credit')
          FemsToastrService.success(res.data.message)
        } else {
          FemsToastrService.error(res.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    getCreditVoucherDebitCoa() {
      VoucherService.getCreditVoucherDebitCoa().then(res => {
        if (res.data.status) {
          this.accountOptions = res.data.data
        } else {
          this.accountOptions = []
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    getCreditVoucherCreditCoa() {
      VoucherService.getCreditVoucherCreditCoa().then(res => {
        if (res.data.status) {
          this.paymentModeOptions = res.data.data
        } else {
          this.paymentModeOptions = []
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    resetCreditVoucher() {
      this.creditVoucherFormData = {}
      this.customerState = null
      this.debitState = null
      this.creditState = null
      this.amountState = null
      this.noteState = null
    },
    // Audit Activity
    onActivityLog() {
      this.isLoadingAudit = true
      this.$bvModal.show('credit-activity-modal')
      this.auditServerParams = {
        dateRange: {},
        columnFilters: {},
        sort: [{ field: '', type: '' }],
        page: 1,
        perPage: 10,
      }
      this.getAuditGridData()
    },

    onColumnFilterAuditServer(params) {
      this.auditServerParams = params
      console.log(this.auditServerParams)
      if (JSON.stringify(this.auditServerParams) === JSON.stringify(params)) {
        this.getAuditGridData()
      }
    },
    onRefreshAudit() {
      this.isLoadingAudit = true
      this.auditServerParams = {
        columnFilters: {},
        dateRange: {},
        sort: [{ field: '', type: '' }],
        page: 1,
        perPage: 10,
      }
      this.getAuditGridData()
    },
    getAuditGridData() {
      // this.isLoading = true
      AuditService.getAllByTag(this.tag, this.auditServerParams)
        .then(res => {
          const response = res.data
          this.isLoadingAudit = false
          this.audits = response.data
          this.auditTotal = response.total
        })
        .catch(() => {
          this.isLoadingAudit = false
          FemsToastrService.error('Something went wrong')
        })
    },
  },
}
</script>
