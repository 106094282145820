<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Debit Voucher
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <b-modal
      id="debit-voucher-modal"
      no-close-on-backdrop
      title="Add Debit Voucher"
      ok-title="Save"
      cancel-variant="outline-secondary"
      @ok="saveDebitVoucher"
      @cancel="resetDebitVoucher"
      @close="resetDebitVoucher"
      @ok.prevent
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Requisition No"
              label-for="requisition_no"
              label-cols-md="3"
              :state="requisition_idState"
              invalid-feedback="Requisition Id is Required"
            >
              <b-form-input
                id="requisition_no"
                v-model="debitVoucherFormData.requisition_id"
                type="number"
                placeholder="Requisition No"
                :state="requisition_idState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Requisition Amount"
              label-for="requisition_amount"
              label-cols-md="3"
              :state="requisition_amountState"
              invalid-feedback="Requisition Amount is Required"
            >
              <b-form-input
                id="requisition_amount"
                v-model="debitVoucherFormData.requisition_amount"
                type="number"
                placeholder="Requisition Amount"
                :state="requisition_amountState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Paid To"
              label-for="paid_to"
              label-cols-md="3"
              :state="paid_toState"
              invalid-feedback="Type is Required"
            >
              <b-form-input
                id="paid_to"
                v-model="debitVoucherFormData.paid_to"
                placeholder="Type..."
                :state="paid_toState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Debit"
              label-for="expense_head"
              label-cols-md="3"
              :state="debitState"
              invalid-feedback="Expense Head is Required"
            >
              <v-select
                v-model="debitVoucherFormData.debit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                placeholder="Select Expense Head"
                :options="expenseOptions"
                :reduce="val => val.id"
                :state="debitState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Credit"
              label-for="payment_mode"
              label-cols-md="3"
              :state="creditState"
              invalid-feedback="Payment Mode is Required"
            >
              <v-select
                v-model="debitVoucherFormData.credit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                placeholder="Select Payment Mode"
                :options="paymentModeOptions"
                :reduce="val => val.id"
                :state="creditState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Amount"
              label-for="amount"
              label-cols-md="3"
              :state="amountState"
              invalid-feedback="Amount is Required"
            >
              <b-form-input
                id="amount"
                v-model="debitVoucherFormData.amount"
                type="number"
                placeholder="Amount"
                :state="amountState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Note"
              label-for="note"
              label-cols-md="3"
              :state="noteState"
              invalid-feedback="Note is Required"
            >
              <b-form-textarea
                id="note"
                v-model="debitVoucherFormData.note"
                placeholder="Reason (Required)"
                rows="3"
                max-rows="6"
                :state="noteState"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <!-- <b-col cols="12">
            <b-form-group
              label-for="buttons"
              label-cols-md="3"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="saveDebitVoucher"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-form-group>
          </b-col> -->
        </b-row>
      </b-form>
    </b-modal>

    <voucher-table
      voucher-type="debit"
      :debit-coa="getDebitVoucherDebitCoa"
      :credit-coa="getDebitVoucherCreditCoa"
    />
    <!-- Audit Activity -->
    <b-modal
      id="activity-modal"
      no-close-on-backdrop
      title="Debit Voucher activity log"
      size="lg"
      hide-footer
    >
      <div :style="isLoadingAudit ? { paddingBottom:'60px'} : { padding:''}">
        <preloader
          v-if="isLoadingAudit"
        />
        <activity-timeline
          v-if="!isLoadingAudit"
          :rows="audits"
          :total-records="auditTotal"
          :server-props-params="auditServerParams"
          :on-column-filter-server="onColumnFilterAuditServer"
          :refresh-grid="onRefreshAudit"
        />
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VoucherTable from './VoucherTable.vue'
import AuditService from '@/@service/api/Audit.service'
import Preloader from '../components/preloader/preloader.vue'
import ActivityTimeline from '../audit/components/ActivityTimeline.vue'

import VoucherService from '@/@service/api/accounting/Voucher.service'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormTextarea,
    vSelect,
    VoucherTable,
    BLink,
    Preloader,
    ActivityTimeline,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      debitVoucherFormData: {},
      expenseSelected: null,
      expenseOptions: [],
      paymentModeSelected: null,
      paymentModeOptions: [],
      requisition_idState: null,
      requisition_amountState: null,
      paid_toState: null,
      debitState: null,
      creditState: null,
      amountState: null,
      noteState: null,

      isLoadingAudit: false,
      tag: 'debit-voucher',
      audits: [],
      auditTotal: 0,
      auditServerParams: {
        columnFilters: {},
        dateRange: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },
  methods: {
    validate() {
      this.requisition_idState = null
      this.requisition_amountState = null
      this.paid_toState = null
      this.debitState = null
      this.creditState = null
      this.amountState = null
      this.noteState = null
      if (!this.debitVoucherFormData.requisition_id) {
        this.requisition_idState = false
      }
      if (!this.debitVoucherFormData.requisition_amount) {
        this.requisition_amountState = false
      }
      if (!this.debitVoucherFormData.paid_to) {
        this.paid_toState = false
      }
      if (!this.debitVoucherFormData.debit || (this.debitVoucherFormData.debit && this.debitVoucherFormData.debit.length < 0)) {
        this.debitState = false
      }
      if (!this.debitVoucherFormData.credit || (this.debitVoucherFormData.credit && this.debitVoucherFormData.credit.length < 0)) {
        this.creditState = false
      }
      if (!this.debitVoucherFormData.amount) {
        this.amountState = false
      }
      if (!this.debitVoucherFormData.note) {
        this.noteState = false
      }

      if (this.requisition_idState !== false && this.requisition_amountState !== false && this.paid_toState !== false
        && this.debitState !== false && this.creditState !== false && this.amountState !== false
        && this.noteState !== false) {
        return true
      }
      return false
    },
    saveDebitVoucher() {
      if (!this.validate()) {
        return
      }
      VoucherService.saveDebitVoucher(this.debitVoucherFormData).then(res => {
        if (res.data.status) {
          this.debitVoucherFormData = {}
          this.$root.$emit('bv::hide::modal', 'debit-voucher-modal')
          this.$root.$emit('voucherSubmitted', 'debit')
          FemsToastrService.success(res.data.message)
        } else {
          FemsToastrService.error(res.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    getDebitVoucherDebitCoa() {
      VoucherService.getDebitVoucherDebitCoa().then(res => {
        if (res.data.status) {
          this.expenseOptions = res.data.data
        } else {
          this.expenseOptions = []
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    getDebitVoucherCreditCoa() {
      VoucherService.getDebitVoucherCreditCoa().then(res => {
        if (res.data.status) {
          this.paymentModeOptions = res.data.data
        } else {
          this.paymentModeOptions = []
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    resetDebitVoucher() {
      this.debitVoucherFormData = {}
      this.requisition_idState = null
      this.requisition_amountState = null
      this.paid_toState = null
      this.debitState = null
      this.creditState = null
      this.amountState = null
      this.noteState = null
    },

    // Audit Activity
    onActivityLog() {
      this.isLoadingAudit = true
      this.$bvModal.show('activity-modal')
      this.auditServerParams = {
        dateRange: {},
        columnFilters: {},
        sort: [{ field: '', type: '' }],
        page: 1,
        perPage: 10,
      }
      this.getAuditGridData()
    },

    onColumnFilterAuditServer(params) {
      this.auditServerParams = params
      console.log(this.auditServerParams)
      if (JSON.stringify(this.auditServerParams) === JSON.stringify(params)) {
        this.getAuditGridData()
      }
    },
    onRefreshAudit() {
      this.isLoadingAudit = true
      this.auditServerParams = {
        columnFilters: {},
        dateRange: {},
        sort: [{ field: '', type: '' }],
        page: 1,
        perPage: 10,
      }
      this.getAuditGridData()
    },
    getAuditGridData() {
      // this.isLoading = true
      AuditService.getAllByTag(this.tag, this.auditServerParams)
        .then(res => {
          const response = res.data
          this.isLoadingAudit = false
          this.audits = response.data
          this.auditTotal = response.total
        })
        .catch(() => {
          this.isLoadingAudit = false
          FemsToastrService.error('Something went wrong')
        })
    },
  },
}
</script>
