<template>
  <b-card>
    <b-modal
      id="journal-voucher-modal"
      no-close-on-backdrop
      title="Add Journal Voucher"
      ok-title="Save"
      cancel-variant="outline-secondary"
      @ok="saveJournalVoucher"
      @cancel="resetJournalVoucher"
      @close="resetJournalVoucher"
      @ok.prevent
    >
      <b-form
        ref="debitHeadForm"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeatDebit"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="is_advance">
              <b-form-checkbox
                id="checkbox-1"
                v-model="isAdvance"
                name="is_advance"
              >
                Advance
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isAdvance">
          <b-col cols="12">
            <b-form-group
              label="Received Date"
              label-for="received_date"
              label-cols-md="3"
            >
              <b-form-datepicker
                id="received_date"
                v-model="journalVoucherFormData.receivedDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Requisition No"
              label-for="requisition_no"
              label-cols-md="3"
            >
              <b-form-input
                id="requisition_no"
                v-model="journalVoucherFormData.requisition_id"
                type="number"
                placeholder="Requisition No"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Requisition Amount:"
              label-for="requisition_amount"
              label-cols-md="3"
            >
              <b-form-input
                id="requisition_amount"
                v-model="journalVoucherFormData.requisition_amount"
                type="number"
                placeholder="Requisition Amount"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Paid To"
              label-for="paid_to"
              label-cols-md="3"
            >
              <b-form-input
                id="paid_to"
                v-model="journalVoucherFormData.paid_to"
                placeholder="Employee ID/Name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <h4>Debit</h4>
          </b-col>
        </b-row>
        <b-row
          v-for="(debitHead, index) in debitHeads"
          :id="debitHead.id"
          :key="debitHead.id"
          ref="debitHeadRow"
        >
          <b-col cols="6">
            <b-form-group label-for="debit_head">
              <v-select
                v-model="debitHead.expenseSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                placeholder="Select Debit Head"
                :options="expenseOptions"
                :reduce="val => val.id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="amount">
              <b-form-input
                id="amount"
                v-model="debitHead.amount"
                type="number"
                min="0"
                placeholder="Amount"
              />
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col cols="2">
            <b-button
              v-if="debitHead.id == 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="repeatDebit"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
            <b-button
              v-if="debitHead.id != 1"
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              @click="removeDebitHeadRow(index)"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-form
        ref="creditHeadForm"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeatCredit"
      >
        <b-row>
          <b-col cols="12">
            <h4>Credit</h4>
          </b-col>
        </b-row>
        <b-row
          v-for="(creditHead, index) in creditHeads"
          :id="creditHead.id"
          :key="creditHead.id"
          ref="creditHeadRow"
        >
          <b-col cols="6">
            <b-form-group label-for="credit_head">
              <v-select
                v-model="creditHead.expenseSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                placeholder="Select Credit Head"
                :options="paymentModeOptions"
                :reduce="val => val.id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="amount">
              <b-form-input
                id="amount"
                v-model="creditHead.amount"
                type="number"
                min="0"
                placeholder="Amount"
              />
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col cols="2">
            <b-button
              v-if="creditHead.id == 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="repeatCredit"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
            <b-button
              v-if="creditHead.id != 1"
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              @click="removeCreditHeadRow(index)"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>

      <br>
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="note">
              <b-form-textarea
                id="note"
                v-model="journalVoucherFormData.note"
                placeholder="Reason (Required)"
                rows="3"
                max-rows="6"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <!-- <b-col cols="12">
            <b-form-group
              label-for="buttons"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="saveJournalVoucher"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-form-group>
          </b-col> -->
        </b-row>
      </b-form>
    </b-modal>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Journal Voucher
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <voucher-table
      voucher-type="journal"
      :debit-coa=" getJournalVoucherDebitCoa"
      :credit-coa="getJournalVoucherCreditCoa"
    />

    <!--    activiy modal-->
    <b-modal
      id="journal-activity-modal"
      no-close-on-backdrop
      title="Journal Voucher activity log"
      size="lg"
      hide-footer
    >
      <div :style="isLoadingAudit ? { paddingBottom:'60px'} : { padding:''}">
        <preloader
          v-if="isLoadingAudit"
        />
        <activity-timeline
          v-if="!isLoadingAudit"
          :rows="audits"
          :total-records="auditTotal"
          :server-props-params="auditServerParams"
          :on-column-filter-server="onColumnFilterAuditServer"
          :refresh-grid="onRefreshAudit"
        />
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
  BFormTextarea, BFormDatepicker, BFormCheckbox, BLink,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VoucherTable from './VoucherTable.vue'
import AuditService from '@/@service/api/Audit.service'
import Preloader from '../components/preloader/preloader.vue'
import ActivityTimeline from '../audit/components/ActivityTimeline.vue'

import VoucherService from '@/@service/api/accounting/Voucher.service'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    vSelect,
    BFormDatepicker,
    BFormCheckbox,
    VoucherTable,
    BLink,
    Preloader,
    ActivityTimeline,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      journalVoucherFormData: {},
      expenseSelected: null,
      isAdvance: false,
      expenseOptions: [],
      paymentModeSelected: null,
      paymentModeOptions: [],
      debitHeads: [{
        id: 1,
        expenseSelected: '',
        amount: '',
        prevHeight: 0,
      }],
      nextDebitHeadId: 1,
      creditHeads: [{
        id: 1,
        expenseSelected: '',
        amount: '',
        prevHeight: 0,
      }],
      nextCreditHeadId: 1,

      isLoadingAudit: false,
      tag: 'journal-voucher',
      audits: [],
      auditTotal: 0,
      auditServerParams: {
        columnFilters: {},
        dateRange: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },

    }
  },
  methods: {
    repeatDebit() {
      this.debitHeads.push({
        id: this.nextDebitHeadId += 1,
      })
    },
    repeatCredit() {
      this.creditHeads.push({
        id: this.nextCreditHeadId += 1,
      })
    },
    removeDebitHeadRow(index) {
      this.debitHeads.splice(index, 1)
    },
    removeCreditHeadRow(index) {
      this.creditHeads.splice(index, 1)
    },
    saveJournalVoucher() {
      if (!this.debitHeads[0].expenseSelected || !this.creditHeads[0].expenseSelected) {
        FemsToastrService.error('Please select at least one debit and one credit head!')
      }

      const dcData = this.prepareDebitCreditData()
      if (dcData.error) {
        FemsToastrService.error(dcData.error)

        return
      }
      this.journalVoucherFormData.debitRows = JSON.stringify(dcData.debits)
      this.journalVoucherFormData.creditRows = JSON.stringify(dcData.credits)
      VoucherService.saveJournalVoucher(this.journalVoucherFormData)
        .then(res => {
          if (res.data.status) {
            this.journalVoucherFormData = {}
            this.$root.$emit('bv::hide::modal', 'journal-voucher-modal')
            this.$root.$emit('voucherSubmitted', 'journal')
            this.resetForms()
            FemsToastrService.success(res.data.message)
          } else {
            FemsToastrService.error(res.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    prepareDebitCreditData() {
      let error = false
      let errorMessage = null
      const debits = {}
      this.debitHeads.forEach(item => {
        if (debits[item.expenseSelected]) {
          error = true
          errorMessage = 'Same debit head cannot be used more than once!'
        } else if (!item.amount || item.amount === '' || item.amount === 0) {
          error = true
          errorMessage = 'Please specify a valid amount!'
        } else {
          debits[item.expenseSelected] = item.amount
        }
      })

      const credits = {}
      this.creditHeads.forEach(item => {
        if (!item.amount || item.amount === '' || item.amount === 0) {
          error = true
        }
        if (credits[item.expenseSelected]) {
          error = true
          errorMessage = 'Same credit head cannot be used more than once!'
        } else if (!item.amount || item.amount === '' || item.amount === 0) {
          error = true
          errorMessage = 'Please specifiy a valid amount!'
        } else {
          credits[item.expenseSelected] = item.amount
        }
      })

      if (error) {
        return { error: errorMessage }
      }

      return {
        debits,
        credits,
      }
    },
    getJournalVoucherDebitCoa() {
      VoucherService.getJournalVoucherDebitCoa().then(res => {
        if (res.data.status) {
          this.expenseOptions = res.data.data
        } else {
          FemsToastrService.error(res.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    getJournalVoucherCreditCoa() {
      VoucherService.getJournalVoucherCreditCoa().then(res => {
        if (res.data.status) {
          this.paymentModeOptions = res.data.data
        } else {
          FemsToastrService.error(res.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    resetForms() {
      this.$refs.debitHeadForm.reset()
      this.$refs.creditHeadForm.reset()
    },
    resetJournalVoucher() {
      this.journalVoucherFormData = {}
      this.resetForms()
    },

    // Audit Activity
    onActivityLog() {
      this.isLoadingAudit = true
      this.$bvModal.show('journal-activity-modal')
      this.auditServerParams = {
        dateRange: {},
        columnFilters: {},
        sort: [{ field: '', type: '' }],
        page: 1,
        perPage: 10,
      }
      this.getAuditGridData()
    },

    onColumnFilterAuditServer(params) {
      this.auditServerParams = params
      console.log(this.auditServerParams)
      if (JSON.stringify(this.auditServerParams) === JSON.stringify(params)) {
        this.getAuditGridData()
      }
    },
    onRefreshAudit() {
      this.isLoadingAudit = true
      this.auditServerParams = {
        columnFilters: {},
        dateRange: {},
        sort: [{ field: '', type: '' }],
        page: 1,
        perPage: 10,
      }
      this.getAuditGridData()
    },
    getAuditGridData() {
      // this.isLoading = true
      AuditService.getAllByTag(this.tag, this.auditServerParams)
        .then(res => {
          const response = res.data
          this.isLoadingAudit = false
          this.audits = response.data
          this.auditTotal = response.total
        })
        .catch(() => {
          this.isLoadingAudit = false
          FemsToastrService.error('Something went wrong')
        })
    },
  },
}
</script>
