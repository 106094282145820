import axiosIns from '@/libs/axios'

export default {
  getDebitVoucherDebitCoa: () => axiosIns.get('accounting/voucher/debit-voucher/debit-coa'),
  getDebitVoucherCreditCoa: () => axiosIns.get('accounting/voucher/debit-voucher/credit-coa'),
  getJournalVoucherDebitCoa: () => axiosIns.get('accounting/voucher/journal-voucher/debit-coa'),
  getJournalVoucherCreditCoa: () => axiosIns.get('accounting/voucher/journal-voucher/credit-coa'),
  getCreditVoucherDebitCoa: () => axiosIns.get('accounting/voucher/credit-voucher/debit-coa'),
  getCreditVoucherCreditCoa: () => axiosIns.get('accounting/voucher/credit-voucher/credit-coa'),
  getAll: params => axiosIns.post('accounting/voucher/voucher-list', params),
  getOne: id => axiosIns.get(`accounting/voucher/voucher-show/${id}`),
  saveDebitVoucher: params => axiosIns.post('accounting/voucher/debit-voucher/save', params),
  saveCreditVoucher: params => axiosIns.post('accounting/voucher/credit-voucher/save', params),
  saveJournalVoucher: params => axiosIns.post('accounting/voucher/journal-voucher/save', params),
}
