<template>
  <div>

    <!-- Filters -->
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          variant="outline-primary"
          style="text-transform: capitalize"
          @click="showModal"
        >
          Add {{ voucherType }} Voucher
        </b-button>
      </b-form-group>
      <b-form-group class="col-5 mr-0 pr-0">
        <div class="d-flex align-items-center">
          <label class="mr-1">From</label>
          <b-form-datepicker
            id="voucher_start_date"
            v-model="voucherStartDate"
            class="mr-1"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            @input="startDateChanged"
          />
          <label class="mr-1">To</label>
          <b-form-datepicker
            id="voucher_end_date"
            v-model="voucherEndDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            @input="endDateChanged"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-primary"
            >View</b-button>
            <b-button
              class="mr-1 px-1"
              variant="outline-primary"
            >Print</b-button>
            <b-button
              v-if="props.formattedRow.type == 2 && props.formattedRow.is_advance"
              class="px-1"
              variant="outline-warning"
            >Reconcile</b-button>
          </span>
        </span>

        <span v-else-if="props.column.field === 'type'">
          {{ getVoucherType(props.formattedRow[props.column.field]) }}
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormSelect, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

import VoucherService from '@/@service/api/accounting/Voucher.service'
// import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormDatepicker,
    BButton,
  },
  props: {
    voucherType: {
      type: [String, null],
      default: 'debit',
    },
    // eslint-disable-next-line vue/require-default-prop
    debitCoa: Function,
    // eslint-disable-next-line vue/require-default-prop
    creditCoa: Function,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Voucher ID',
          field: 'voucher_no',
        },
        {
          label: 'Voucher Type',
          field: 'type',
        },
        {
          label: 'Date',
          field: 'created_at',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      debitVoucherRows: [],
      journalVoucherRows: [],
      creditVoucherRows: [],
      searchTerm: '',
      voucherStartDate: new Date(),
      voucherEndDate: new Date(),
      gridVoucherType: {},
      gridVoucherTypeOptions: [
        { name: 'Debit Voucher', id: '1' },
        { name: 'Journal Voucher', id: '2' },
        { name: 'Credit Voucher', id: '3' },
      ],
    }
  },
  computed: {
    typeVariant() {
      const typeLabel = {
        1: 'Debit',
        2: 'Journal',
        3: 'Advance',
        4: 'Credit',
      }

      return type => typeLabel[type]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    modalId() {
      return 'debit-voucher-modal'
    },

  },
  watch: {
    voucherType(newVal) {
      if (['', null, undefined].includes(newVal)) {
        this.rows = []
      } else {
        this.getVoucherGridData(newVal)
      }
    },

  },
  created() {
    this.getVoucherGridData()
  },
  mounted() {
    if (this.voucherType === 'debit') {
      this.rows = this.debitVoucherRows
    } else if (this.voucherType === 'credit') {
      this.rows = this.creditVoucherRows
    } else if (this.voucherType === 'journal') {
      this.rows = this.journalVoucherRows
    }

    this.$root.$on('voucherSubmitted', () => {
      this.getVoucherGridData()
    })
  },
  methods: {
    getVoucherGridData() {
      let vType = 1
      if (this.voucherType === 'journal') vType = 2
      if (this.voucherType === 'advance') vType = 3
      if (this.voucherType === 'credit') vType = 4
      const params = {
        voucherType: vType,
        fromDate: this.voucherStartDate,
        toDate: this.voucherEndDate,
      }
      VoucherService.getAll(params).then(res => {
        this.rows = res.data.data
        this.allData = res.data.data
        this.$root.$emit('bv::hide::modal', `${this.voucherType}-voucher-modal`)
      })
    },
    showModal() {
      // TODO:: check voucher type according to tab
      this.debitCoa()
      this.creditCoa()
      this.$root.$emit('bv::show::modal', `${this.voucherType}-voucher-modal`)
    },
    getVoucherType(type) {
      const typeLabel = {
        1: 'Debit',
        2: 'Journal',
        3: 'Advance',
        4: 'Credit',
      }

      return typeLabel[type]
    },
    startDateChanged(date) {
      this.filterRowDataByDate(date, 'start')
    },
    endDateChanged(date) {
      this.filterRowDataByDate(date, 'end')
    },
    filterRowDataByDate(date, type) {
      this.rows = this.allData.filter(item => {
        if (type === 'start') {
          return new Date(item.created_at) >= new Date(this.voucherStartDate)
        }
        if (type === 'end') {
          return new Date(item.created_at) <= new Date(new Date(this.voucherEndDate).getTime() + (24 * 60 * 60 * 1000))
        }
        return true
      })
    },
  },
}
</script>
