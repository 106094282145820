<template>
  <b-card>
    <b-tabs>
      <b-tab
        active
        title="Debit Vouchers"
      >
        <debit-voucher-form />
      </b-tab>
      <b-tab title="Journal Vouchers">
        <journal-voucher-form />
      </b-tab>
      <b-tab title="Credit Vouchers">
        <credit-voucher-form />
      </b-tab>
    </b-tabs>

  </b-card>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import DebitVoucherForm from './DebitVoucherForm.vue'
import JournalVoucherForm from './JournalVoucherForm.vue'
import CreditVoucherForm from './CreditVoucherForm.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    DebitVoucherForm,
    JournalVoucherForm,
    CreditVoucherForm,
  },
  data() {
    return {}
  },
}
</script>
