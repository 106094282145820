<template>
  <b-row class="match-height">
    <b-col lg="12">
      <voucher-tabs />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import VoucherTabs from './VoucherTab.vue'

export default {
  components: {
    BRow,
    BCol,
    VoucherTabs,
  },
}
</script>
